<template>
    <div class="detailsCampaign">
        <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
            <div class="d-flex">
                <div class="pr-3 h4">
                    <span
                        ><strong>{{ info.details.product }}</strong></span
                    >
                </div>
                <div class="d-flex align-items-center gap">
                    <div class="h5" v-if="info.brand">
                        <span
                            >{{ info.brand.slice(0, 1).toUpperCase() + info.brand.slice(1) }}
                            |
                        </span>
                    </div>
                    <div class="h5 d-flex" v-if="info.details.point_code">
                        <span> {{ info.details.point_code[0] }}</span>
                    </div>
                    <div class="h5 d-flex" v-if="info.details.point_text">
                        <span>{{ info.details.point_text[0] }}</span>
                    </div>
                </div>
            </div>
        </div>

        <template>
            <b-card>
                <b-row class="d-flex align-items-baseline justify-content-between pl-1 pr-1">
                    <div @click="toggleCollapse" variant="primary" style="cursor: pointer">
                        <div class="d-flex">
                            <h4><strong>Informations générales</strong></h4>

                            <div class="ml-1">
                                <img src="@/assets/images/icons/arrow-down.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-baseline">
                        <strong>Statut:</strong>

                        <StatusBadge :status="info.status" :statusDetail="info.statusDetail" />
                        <div v-if="info.status >= 7">
                            <b-button variant="primary" class="btn-icon mr-1" @click="downloadReport(info)">
                                <feather-icon icon="DownloadIcon" />
                                Bilan de campagne
                            </b-button>
                            <b-button variant="primary" class="btn-icon mr-1" @click="tradingRoute(info.campaign_code)">
                                <feather-icon icon="Edit2Icon" />
                                Éditer
                            </b-button>
                        </div>
                        <div v-else>
                            <ButtonActionsComponent :info="info" @update="getCampaign()" />
                        </div>
                    </div>
                </b-row>

                <b-collapse id="collapse-1" v-model="isCollapsed">
                    <div class="d-flex flex-column p-1" style="gap: 25px">
                        <b-row>
                            <div class="align-items-baseline pt-2">
                                <p class="">CODE CAMPAGNE :</p>
                                <div style="height: 20px" class="code-to-copy">
                                    <span class="code" data-target="#tocopy"> {{ info.campaign_code }}</span>
                                    <span @click="dataToCopy" style="cursor: pointer" data-toggle="tooltip" data-placement="top" title="copier" class="ml-1">
                                        <img src="@/assets/images/icons/button-copy.png" alt="" style="width: 32px" />
                                    </span>
                                    <input type="hidden" id="code-to-copy" :value="`${info.campaign_code}`" />
                                </div>
                            </div>
                        </b-row>
                        <b-row cols="2">
                            <div>
                                <p>MONTANT CLIENT</p>
                                <div class="d-flex flex-row align-items-center">
                                    <strong>Montant total : </strong>
                                    <span class="ml-1">{{ billing.campaignTotalAmount.toLocaleString('fr-FR') }} €</span>
                                </div>
                            </div>
                            <div>
                                <p>PÉRIODE</p>
                                <div class="d-flex justify-content-evenly" style="display: flex; gap: 45px">
                                    <div class="d-flex align-items-center">
                                        <strong>Date de début :</strong>
                                        <span class="ml-1">
                                            {{ new Date(this.info.start_date).toLocaleDateString() }}
                                        </span>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <strong>Date de fin :</strong>
                                        <span class="ml-1">
                                            {{ new Date(this.info.end_date).toLocaleDateString() }}
                                        </span>
                                    </div>
                                    <div style="cursor: pointer" @click="startDateCampaign(info.start_date, info.end_date, info.campaign_code)">
                                        <img src="@/assets/images/icons/modify-icon.png" alt="Modifier les date de la campagne" />
                                    </div>
                                </div>
                            </div>
                        </b-row>
                        <b-row cols="3" class="align-items-end">
                            <div>
                                <p>PAIEMENT</p>
                                <div class="d-flex flex-row justify-content-between">
                                    <div class="d-flex flex-row">
                                        <strong>Total (HT) : </strong>
                                        <span class="ml-1">{{ billing.campaignTotalAmount.toLocaleString('fr-FR') }} €</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-row">
                                    <strong>Acompte (HT) : </strong>
                                    <span class="ml-1">
                                        {{ billing.depositAmountHT.toLocaleString('fr-FR') }}
                                        €</span
                                    >
                                </div>
                            </div>
                            <div>
                                <div class="d-flex flex-row">
                                    <strong>Reste à payer (HT) : </strong>
                                    <span class="ml-1">{{ billing.totalLeftAmount.toLocaleString('fr-FR') }} €</span>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </b-collapse>
            </b-card>
        </template>

        <b-tabs content-class="mt-30" v-model="activeTab">
            <b-tab title="Global">
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab"></div>

                    <b-card class="mt-2">
                        <b-row>
                            <div class="flex justify-content-between">
                                <span class="ml-2 h3 p-2"
                                    ><strong>Suivi par média </strong><span v-if="info.children">({{ info.children.length }})</span></span
                                >
                                <b-col lg="4">
                                    <b-form-group label="Filtrer" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0 items-content-center flex">
                                        <b-input-group size="sm">
                                            <b-form-input id="filter-input" class="w-75" v-model.debounce="filter" debounce="200" placeholder="par média"></b-form-input>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </div>
                        </b-row>

                        <b-table
                            :items="items"
                            :fields="fields"
                            :filter-included-fields="['media']"
                            :filter="filter"
                            :current-page="currentPage"
                            :per-page="perPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            stacked="md"
                            outlined
                            responsive
                        >
                            <template #cell(media)="row">
                                <div class="flex justify-content-between align-items-center pl-2">
                                    <div>
                                        <span class="pr-1"><img :src="row.value.icon" alt="child icon" style="width: 20px" /></span>
                                        <span class="font-weight-bold">{{ row.value.name }}</span>
                                    </div>

                                    <div
                                        v-if="row.item.more.status === 1 && !row.item.more.provider && !row.item.more.provider_data"
                                        style="font-size: 11px; border-radius: 30px; padding: 5px 12px; border: 1px solid rgba(75, 70, 92, 0.6)"
                                    >
                                        <svg width="10" height="10">
                                            <circle cx="5" cy="5" r="5" fill="#EA5455" />
                                        </svg>
                                        <span class="font-weight-bold" style="margin-left: 7px">Liaison manquante</span>
                                    </div>
                                </div>
                            </template>
                            <template #cell(budget)="row">
                                <span v-if="row.item.more.provider === 'googleads' || row.item.more.media === 'ADW'">—</span>

                                <span v-else-if="row.item.more.media === 'YOU'">
                                    <span
                                        v-if="row.item.budgetCampaign"
                                        class="h5"
                                        :class="budgetClassColor((row.item.more.price - row.item.budgetCampaign).toFixed(2), row.item.budgetCampaign.toFixed(2), row.item.more.package)"
                                        >{{ row.item.budgetCampaign.toFixed(2) }} €</span
                                    >
                                    / {{ (row.value / 100).toLocaleString('fr-FR') }} €</span
                                >

                                <span v-else>
                                    <span v-if="row.item.budgetCampaign" class="h5" :class="budgetClassColor(row.item.budgetDifference, row.item.budget, row.item.more.package)"
                                        >{{ row.item.budgetCampaign.toFixed(2) }} €</span
                                    >
                                    / {{ (row.value / 100).toLocaleString('fr-FR') }} €</span
                                >
                            </template>

                            <template #cell(dailyLogs)="row">
                                <span>{{ row.value.daysCampaignRealized.toLocaleString('fr-FR') }}</span>
                                /
                                <span>{{ row.value.daysCampaign.toLocaleString('fr-FR') }}</span>
                            </template>

                            <template #cell(impressions)="row">
                                <div v-if="Object.keys(row.value).length > 0" style="display: flex; justify-content: flex-end">
                                    <div style="display: flex; flex-direction: column; align-self: center">
                                        <span v-if="row.item.goal != 'video_quartile_p100' && row.item.details.sum_of_impressions" class="font-weight-bold">
                                            {{ row.item.details.sum_of_impressions.toLocaleString('fr-FR') }}
                                        </span>
                                        <span v-else-if="row.item.goal === 'video_quartile_p100' && row.item.details.sum_of_views" class="font-weight-bold">
                                            {{ row.item.details.sum_of_views.toLocaleString('fr-FR') }}
                                        </span>

                                        <span v-if="row.item.quantity.total && row.item.goal === 'video_quartile_p100'" style="font-size: 11px">
                                            Objectif :
                                            {{ row.item.quantity.total.toLocaleString('fr-FR') }}
                                        </span>
                                        <span v-else-if="row.value.objectif && row.item.goal === 'impressions'" style="font-size: 11px">
                                            Objectif :
                                            {{ row.value.objectif.toLocaleString('fr-FR') }}
                                        </span>
                                    </div>

                                    <RadialBarChart v-if="!row.item.more.package && row.item.goal === 'impressions'" :value="row.value.objectivePercentage" style="width: 50%" />
                                    <RadialBarChart v-else-if="!row.item.more.package && row.item.goal === 'video_quartile_p100'" :value="row.item.views.objectivePercentage" style="width: 50%" />
                                    <RadialBarChart v-else :value="row.value.objectivePercentage" style="width: 50%; visibility: hidden" />
                                </div>
                            </template>
                            <template #cell(clics)="row">
                                <div v-if="Object.keys(row.value).length > 0" style="display: flex; justify-content: flex-end">
                                    <div style="display: flex; flex-direction: column; align-self: center">
                                        <span v-if="row.item.details.sum_of_clicks" class="font-weight-bold;">
                                            {{ row.item.details.sum_of_clicks.toLocaleString('fr-FR') }}
                                        </span>
                                        <span v-if="row.value.objectif" style="font-size: 11px">
                                            Objectif :
                                            {{ row.value.objectif.toLocaleString('fr-FR') }}
                                        </span>
                                    </div>
                                    <RadialBarChart v-if="!row.item.more.package && row.item.goal === 'clicks'" :value="row.value.objectivePercentage" style="width: 50%" />
                                    <RadialBarChart v-else :value="row.value.objectivePercentage" style="width: 50%; visibility: hidden" />
                                </div>
                            </template>
                            <template #cell(more)="row">
                                <LeverActionsComponent :data="row.value" @updateChildCampaign="getCampaign()" />
                            </template>
                        </b-table>
                        <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
                    </b-card>
                </div>
            </b-tab>
            <b-tab :items="items" v-for="item in items" :key="item.id" v-if="item.media.name" :title="`${item.media.name}`">
                <b-container fluid>
                    <b-row cols="2">
                        <b-col sm="9" cols="1">
                            <div v-if="item.goal === 'impressions' || item.goal === 'video_quartile_p100'">
                                <b-row cols="1">
                                    <b-card-group deck>
                                        <CampaignCardComponent v-if="!item.more.package && item.goal === 'video_quartile_p100'" :item="item" title="Total vues" name="views">
                                            <template #progress>
                                                <span v-if="item.details.sum_of_views >= 0"
                                                    ><strong class="amount">{{ item.details.sum_of_views.toLocaleString('fr-FR') }}</strong>
                                                    <span v-if="item.goal === 'video_quartile_p100' && item.views.objectivePercentage" class="amount-prcnt pl-1 perc"
                                                        >(
                                                        {{
                                                            item.views.objectivePercentage - getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    ></span
                                                >

                                                <strong class="amount" v-if="item.views.objectivePercentage && item.details.sum_of_views >= 0"
                                                    >{{ Math.floor(item.views.objectivePercentage) }}% complété</strong
                                                >
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.details.sum_of_views"
                                                    ><strong>{{ item.availableMetrics.views.toLocaleString('fr-FR') }}</strong>

                                                    {{ item.dailyLogs.daysLeft <= 1 ? 'vue restante / jours' : 'vues restantes / jours' }}
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                        <CampaignCardComponent v-if="!item.more.package && item.goal === 'impressions'" :item="item" title="Total impressions" name="impressions">
                                            <template #progress>
                                                <span v-if="item.totalMetrics.impressions >= 0"
                                                    ><strong class="amount">{{ item.totalMetrics.impressions.toLocaleString('fr-FR') }}</strong>
                                                    <span v-if="item.goal === 'impressions' && item.impressions.objectivePercentage" class="amount-prcnt pl-1 perc"
                                                        >(
                                                        {{
                                                            item.impressions.objectivePercentage -
                                                            getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    ></span
                                                >
                                                <strong class="amount" v-if="item.quantity.pourcentage && item.totalMetrics.impressions >= 0"
                                                    >{{ Math.floor(item.quantity.pourcentage) }}% complété</strong
                                                >
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.totalMetrics.impressions && item.availableMetrics.impressions >= 0 && item.goal === 'impressions'"
                                                    ><strong>{{ item.availableMetrics.impressions.toLocaleString('fr-FR') }}</strong>

                                                    {{ item.dailyLogs.daysLeft <= 1 ? 'impression restante / jours' : 'impressions restantes / jours' }}
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                        <CampaignCardComponent v-if="item.more.package" :item="item" title="Budget dépensé (J-1)" name="budget">
                                            <template #progress>
                                                <div>
                                                    <strong v-if="typeof item.budgetCampaign === 'number'" class="amount">{{ item.budgetCampaign.toFixed(2).toLocaleString('fr-FR') }} €</strong>
                                                    <span v-if="item.budgetPercentage" class="pl-1 perc amount-prcnt"
                                                        >(
                                                        {{
                                                            item.budgetPercentage.toFixed(2) -
                                                            getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    >
                                                </div>
                                                <strong class="amount" v-if="item.budget">{{ (item.budget / 100).toLocaleString('fr-FR') }} €</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableFunds >= 0"
                                                    >Budget restant à investir : <strong>{{ item.availableFunds.toLocaleString('fr-FR') }} € / jour</strong></span
                                                >
                                            </template>
                                        </CampaignCardComponent>

                                        <CampaignCardComponent v-if="!item.more.package" :item="item" title="Budget dépensé (J-1)" name="budget">
                                            <template #progress>
                                                <div>
                                                    <strong class="amount" v-if="typeof item.budgetCampaign === 'number'">{{ item.budgetCampaign.toFixed(2).toLocaleString('fr-FR') }} €</strong>
                                                    <span v-if="item.budgetPercentage">
                                                        <span
                                                            v-if="
                                                                !isNaN(
                                                                    item.budgetPercentage.toFixed(2) -
                                                                        getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                                ) && item.more.media !== 'YOU'
                                                            "
                                                            class="pl-1 perc amount-prcnt"
                                                        >
                                                            ({{
                                                                (
                                                                    item.budgetPercentage.toFixed(2) -
                                                                    getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                                ).toFixed(2)
                                                            }}%)
                                                        </span>
                                                    </span>

                                                    <span v-if="item.more.media === 'YOU'">
                                                        <span class="pl-1 perc amount-prcnt">
                                                            ({{
                                                                item.budgetPercentage.toFixed(2) -
                                                                getVerticalBarValue(Math.round(item.more.price), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                            }}%)
                                                        </span>
                                                    </span>
                                                </div>

                                                <strong class="amount" v-if="item.more.media === 'YOU'">{{ item.more.price.toLocaleString('fr-FR') }} €</strong>
                                                <strong class="amount" v-if="item.budget && item.more.media !== 'YOU'">{{ (item.budget / 100).toLocaleString('fr-FR') }} €</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableFunds >= 0"
                                                    >Budget restant à investir : <strong>{{ item.availableFunds.toLocaleString('fr-FR') }} € / jour</strong></span
                                                >
                                            </template>
                                        </CampaignCardComponent>
                                        <CampaignCardComponent v-if="item.more.package" :item="item" title="Total impressions" name="impressions">
                                            <template #progress>
                                                <span class="amount" v-if="item.totalMetrics.impressions >= 0"
                                                    ><strong>{{ item.totalMetrics.impressions.toLocaleString('fr-FR') }}</strong>
                                                    <span v-if="item.impressions.objectivePercentage" class="pl-1 perc amount-prcnt"
                                                        >(
                                                        {{
                                                            item.impressions.objectivePercentage -
                                                            getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    ></span
                                                >
                                                <strong class="amount" v-if="item.quantity.pourcentage && item.totalMetrics.impressions >= 0"
                                                    >{{ Math.floor(item.quantity.pourcentage) }}% complété</strong
                                                >
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.totalMetrics.impressions && item.availableMetrics.impressions >= 0"
                                                    ><strong>{{ item.availableMetrics.impressions.toLocaleString('fr-FR') }}</strong>

                                                    {{ item.dailyLogs.daysLeft <= 1 ? 'impression restante / jours' : 'impressions restantes / jours' }}
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                    </b-card-group>
                                </b-row>
                            </div>

                            <div v-if="item.goal === 'clicks'">
                                <b-row cols="1">
                                    <b-card-group deck>
                                        <CampaignCardComponent v-if="!item.more.package" :item="item" title="Total clics" name="clicks">
                                            <template #progress>
                                                <span v-if="item.totalMetrics.clics >= 0 && typeof item.totalMetrics.clics === 'number'"
                                                    ><strong class="amount">{{ item.totalMetrics.clics.toLocaleString('fr-FR') }}</strong>
                                                    <span v-if="item.quantity.pourcentage" class="pl-1 perc amount-prcnt"
                                                        >(
                                                        {{
                                                            Math.floor(item.quantity.pourcentage) -
                                                            getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    >
                                                </span>
                                                <strong class="amount" v-if="item.quantity.pourcentage && item.totalMetrics.clics >= 0">{{ Math.floor(item.quantity.pourcentage) }} % complété</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableMetrics.clics >= 0"
                                                    ><strong>{{ item.availableMetrics.clics.toLocaleString('fr-FR') }}</strong>

                                                    {{ item.availableMetrics.clics <= 1 ? 'clic restant / jours' : 'clics restants / jours' }}
                                                </span>
                                            </template>
                                        </CampaignCardComponent>

                                        <CampaignCardComponent v-if="item.more.package" :item="item" title="Budget dépensé (J-1)" name="budget">
                                            <template #progress>
                                                <div>
                                                    <strong class="amount" v-if="typeof item.budgetCampaign === 'number'">{{ item.budgetCampaign.toFixed(2).toLocaleString('fr-FR') }} €</strong>
                                                    <span
                                                        v-if="
                                                            item.budgetPercentage !== Infinity &&
                                                            !isNaN(getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized))
                                                        "
                                                        class="pl-1 perc amount-prcnt"
                                                    >
                                                        (
                                                        {{
                                                            (
                                                                item.budgetPercentage -
                                                                getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                            ).toFixed(2)
                                                        }}%)
                                                    </span>
                                                </div>

                                                <strong class="amount" v-if="item.budget">{{ (item.budget / 100).toLocaleString('fr-FR') }} €</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableFunds >= 0"
                                                    >Budget restant à investir : <strong>{{ item.availableFunds.toLocaleString('fr-FR') }} € / jour</strong></span
                                                >
                                            </template>
                                        </CampaignCardComponent>
                                        <CampaignCardComponent v-if="!item.more.package" :item="item" title="Budget dépensé (J-1)" name="budget">
                                            <template #progress>
                                                <div>
                                                    <strong class="amount" v-if="typeof item.budgetCampaign === 'number'">{{ item.budgetCampaign.toFixed(2).toLocaleString('fr-FR') }} €</strong>
                                                    <span v-if="item.budgetPercentage" class="pl-1 perc amount-prcnt"
                                                        >(
                                                        {{
                                                            item.budgetPercentage.toFixed(2) -
                                                            getVerticalBarValue(Math.round(item.budget / 100), item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    >
                                                </div>
                                                <strong class="amount" v-if="item.budget">{{ (item.budget / 100).toLocaleString('fr-FR') }} €</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableFunds >= 0"
                                                    >Budget restant à investir : <strong>{{ item.availableFunds.toLocaleString('fr-FR') }} € / jour</strong></span
                                                >
                                            </template>
                                        </CampaignCardComponent>
                                        <CampaignCardComponent v-if="item.more.package" :item="item" title="Total clics" name="clicks">
                                            <template #progress>
                                                <span v-if="item.totalMetrics.clics >= 0 && typeof item.totalMetrics.clics === 'number'"
                                                    ><strong class="amount">{{ item.totalMetrics.clics.toLocaleString('fr-FR') }}</strong>
                                                    <span v-if="item.quantity.pourcentage" class="pl-1 perc amount-prcnt"
                                                        >(
                                                        {{
                                                            Math.floor(item.quantity.pourcentage) -
                                                            getVerticalBarValue(item.quantity.total, item.dailyLogs.daysCampaign, item.dailyLogs.daysCampaignRealized)
                                                        }}%)</span
                                                    >
                                                </span>

                                                <strong class="amount" v-if="item.quantity.pourcentage && item.totalMetrics.clics >= 0">{{ Math.floor(item.quantity.pourcentage) }} % complété</strong>
                                            </template>

                                            <template #remaining>
                                                <span v-if="item.availableMetrics.clics >= 0"
                                                    ><strong>{{ item.availableMetrics.clics.toLocaleString('fr-FR') }}</strong>

                                                    {{ item.availableMetrics.clics <= 1 ? 'clic restant / jours' : 'clics restants / jours' }}
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                    </b-card-group>
                                </b-row>
                            </div>

                            <b-row cols="1" style="margin-top: 1rem; margin-bottom: 1rem">
                                <b-card-group deck v-if="Object.keys(item.logsMetrics).length > 0">
                                    <b-card :title="item.goal === 'clicks' ? 'Graph clicks / jours' : 'Graph impressions / jours'">
                                        <MetricsChartComponent :value="item.logsMetrics" />
                                    </b-card>
                                </b-card-group>
                            </b-row>

                            <div v-if="item.goal === 'impressions'">
                                <b-row cols="1">
                                    <b-card-group deck>
                                        <b-card title="Total clics">
                                            <div class="flex justify-content-between h4">
                                                <span v-if="item.totalMetrics.clics >= 0 && typeof item.totalMetrics.clics === 'number'"
                                                    ><strong class="amount">{{ item.totalMetrics.clics.toLocaleString('fr-FR') }}</strong>
                                                </span>
                                            </div>
                                        </b-card>
                                        <CampaignCardComponent :item="item" title="Progression de la campagne" name="progression">
                                            <template #progress>
                                                <strong
                                                    ><span class="amount">{{ item.dailyLogs.daysCampaignRealized.toLocaleString('fr-FR') }}</span>
                                                    {{ item.dailyLogs.daysCampaignRealized <= 1 ? 'jour' : 'jours' }}</strong
                                                >
                                                <strong>
                                                    <span class="amount">{{ item.dailyLogs.daysCampaign.toLocaleString('fr-FR') }} {{ item.dailyLogs.daysCampaign <= 1 ? 'jour' : 'jours' }}</span>
                                                </strong>
                                            </template>

                                            <template #remaining>
                                                <span>
                                                    <strong>
                                                        {{ item.dailyLogs.daysLeft.toLocaleString('fr-FR') }}
                                                        {{ item.dailyLogs.daysLeft <= 1 ? 'jour restant' : 'jours restants' }}
                                                    </strong>
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                    </b-card-group>
                                </b-row>
                            </div>

                            <div v-if="item.goal === 'clicks'">
                                <b-row cols="1">
                                    <b-card-group deck>
                                        <b-card title="Total impressions">
                                            <div class="flex justify-content-between h4">
                                                <span v-if="item.totalMetrics.impressions >= 0"
                                                    ><strong class="amount">{{ item.totalMetrics.impressions.toLocaleString('fr-FR') }}</strong>
                                                </span>
                                            </div>
                                        </b-card>
                                        <CampaignCardComponent :item="item" title="Progression de la campagne" name="progression">
                                            <template #progress>
                                                <strong
                                                    ><span class="amount">{{ item.dailyLogs.daysCampaignRealized.toLocaleString('fr-FR') }}</span>
                                                    {{ item.dailyLogs.daysCampaignRealized <= 1 ? 'jour' : 'jours' }}</strong
                                                >
                                                <strong>
                                                    <span class="amount">{{ item.dailyLogs.daysCampaign.toLocaleString('fr-FR') }} {{ item.dailyLogs.daysCampaign <= 1 ? 'jour' : 'jours' }}</span>
                                                </strong>
                                            </template>

                                            <template #remaining>
                                                <span>
                                                    <strong>
                                                        {{ item.dailyLogs.daysLeft.toLocaleString('fr-FR') }}
                                                        {{ item.dailyLogs.daysLeft <= 1 ? 'jour restant' : 'jours restants' }}
                                                    </strong>
                                                </span>
                                            </template>
                                        </CampaignCardComponent>
                                    </b-card-group>
                                </b-row>
                            </div>

                            <b-row cols="1" style="margin-top: 1rem">
                                <b-card-group deck v-if="item.goal === 'clicks'">
                                    <MetricsCard :state="item.cpc.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPC</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpc.cpcDaily.replace('.', ',') }}</span> €
                                                </strong>
                                                <span v-if="item.cpc.cpcDaily !== '--' && item.cpc.pourcentage !== 0">({{ item.cpc.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpc.cpcCommanded"
                                            >CPC commandé : <strong class="pl-1">{{ item.cpc.cpcCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpm.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPM</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpm.cpmDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpm.cpmDaily !== '--' && item.cpm.pourcentage !== 0">({{ item.cpm.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpm.cpmCommanded"
                                            >CPM commandé : <strong class="pl-1">{{ item.cpm.cpmCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpa.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPA</strong>
                                            <div>
                                                <strong>
                                                    <span class="cost">{{ item.cpa.cpaDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpa.cpaDaily !== '--' && item.cpa.pourcentage !== 0">({{ item.cpa.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpa.cpaCommanded"
                                            >CPA commandé : <strong class="pl-1">{{ item.cpa.cpaCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                </b-card-group>
                                <b-card-group deck v-if="item.goal === 'impressions' && item.more.media !== 'YOU'">
                                    <MetricsCard :state="item.cpm.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPM</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpm.cpmDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpm.cpmDaily !== '--' && item.cpm.pourcentage !== 0">({{ item.cpm.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpm.cpmCommanded"
                                            >CPM commandé : <strong class="pl-1">{{ item.cpm.cpmCommanded.toFixed(2).replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpc.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPC</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpc.cpcDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpc.cpcDaily !== '--' && item.cpc.pourcentage !== 0">({{ item.cpc.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpc.cpcCommanded"
                                            >CPC commandé : <strong class="pl-1">{{ item.cpc.cpcCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpa.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPA</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpa.cpaDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpa.cpaDaily !== '--' && item.cpa.pourcentage !== 0">({{ item.cpa.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpa.cpaCommanded"
                                            >CPA commandé : <strong class="pl-1">{{ item.cpa.cpaCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                </b-card-group>

                                <b-card-group deck v-if="item.more.media === 'YOU'">
                                    <MetricsCard :state="item.cpv.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline">
                                            <strong>CPV</strong>
                                            <div v-if="item.cpv.cpvDaily !== undefined">
                                                <strong>
                                                    <span class="cost">{{ item.cpv.cpvDaily.replace('.', ',') }}</span> €
                                                </strong>
                                                <span v-if="isFinite(item.cpv.pourcentage)">({{ item.cpv.pourcentage.toFixed(2) }}%)</span>
                                            </div>
                                            <div v-else>
                                                <strong><span class="cost">0</span> €</strong>
                                            </div>
                                        </b-row>

                                        <b-row no-gutters v-if="item.cpv.cpvCommanded !== undefined">
                                            CPV commandé : <strong class="pl-1">{{ item.cpv.cpvCommanded.replace('.', ',') }} €</strong>
                                        </b-row>
                                        <b-row no-gutters v-else> CPV commandé :<strong class="pl-1">0 €</strong> </b-row>
                                    </MetricsCard>

                                    <MetricsCard :state="item.cpm.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPM</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpm.cpmDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpm.cpmDaily !== '--' && item.cpm.pourcentage !== 0">({{ item.cpm.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpm.cpmCommanded"
                                            >CPM commandé : <strong class="pl-1">{{ item.cpm.cpmCommanded.toFixed(2).replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpc.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPC</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpc.cpcDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpc.cpcDaily !== '--' && item.cpc.pourcentage !== 0">({{ item.cpc.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpc.cpcCommanded"
                                            >CPC commandé : <strong class="pl-1">{{ item.cpc.cpcCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                    <MetricsCard :state="item.cpa.pourcentage">
                                        <b-row no-gutters class="flex flex-column align-items-baseline"
                                            ><strong class="pr-1">CPA</strong>
                                            <div>
                                                <strong
                                                    ><span class="cost">{{ item.cpa.cpaDaily.replace('.', ',') }}</span> € </strong
                                                ><span v-if="item.cpa.cpaDaily !== '--' && item.cpa.pourcentage !== 0">({{ item.cpa.pourcentage }}%)</span>
                                            </div>
                                        </b-row>
                                        <b-row no-gutters v-if="item.cpa.cpaCommanded"
                                            >CPA commandé : <strong class="pl-1">{{ item.cpa.cpaCommanded.replace('.', ',') }} €</strong></b-row
                                        >
                                    </MetricsCard>
                                </b-card-group>
                            </b-row>
                        </b-col>

                        <b-col sm="3">
                            <b-row cols="1">
                                <b-card>
                                    <StatusBadge :status="item.more.status" />
                                    <b-card-text class="h5 opacity-25 pt-2 pb-1">PÉRIODE</b-card-text>
                                    <b-card-text v-if="item.date.start_date"><strong>Date de début : </strong>{{ new Date(item.date.start_date).toLocaleDateString() }}</b-card-text>
                                    <b-card-text v-if="item.date.end_date"><strong>Date de fin : </strong>{{ new Date(item.date.end_date).toLocaleDateString() }}</b-card-text>
                                </b-card>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-tab>
        </b-tabs>
        <update-date-modal ref="update-date-modal" @on-update-date-campaign="updateDateCampaign"></update-date-modal>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';

    import { BCard, BCardBody, BAvatar } from 'bootstrap-vue';
    import AppEchartDoughnut from '@/@core/components/charts/echart/AppEchartDoughnut.vue';
    import RadialBarChart from '../../../helpers/RadialBarChart.vue';
    import MetricsChartComponent from './MetricsChartComponent.vue';

    import axios from 'axios';
    import ButtonActionsComponent from './ButtonActionsComponent.vue';
    import LeverActionsComponent from './LeverActionsComponent.vue';
    import StatusBadge from './StatusBadge.vue';

    import { _ } from '@/@core/libs/i18n';

    import YoutubeIcon from '@/assets/images/media-icons/youtube.svg';
    import WazeIcon from '@/assets/images/media-icons/waze.svg';
    import FacebookIcon from '@/assets/images/media-icons/facebook.svg';
    import DesktopIcon from '@/assets/images/media-icons/desktop-solid.svg';
    import GoogleIcon from '@/assets/images/media-icons/google.svg';
    import LinkedinIcon from '@/assets/images/media-icons/linkedin.svg';
    import MobileIcon from '@/assets/images/media-icons/mobile-screen-button-solid.svg';
    import SoundIcon from '@/assets/images/media-icons/play-solid.svg';
    import VideoIcon from '@/assets/images/media-icons/video-solid.svg';
    import TVIcon from '@/assets/images/media-icons/tv-solid.svg';
    import MetricsCard from './MetricsCard.vue';

    import { BProgress, BProgressBar, BButton } from 'bootstrap-vue';
    import { BCollapse } from 'bootstrap-vue';

    import http from '@/helpers/http';
    import FileSaver from 'file-saver';
    import CampaignCardComponent from './CampaignCardComponent.vue';
    import UpdateDateModal from '../UpdateDateModal/update-date-modal.vue';

    export default {
        components: {
            BCard,
            BCardBody,
            BAvatar,
            AppEchartDoughnut,
            RadialBarChart,
            MetricsChartComponent,
            ButtonActionsComponent,
            LeverActionsComponent,
            StatusBadge,
            MetricsCard,
            BProgress,
            BProgressBar,
            BButton,
            BButton,
            BCollapse,
            BCard,
            CampaignCardComponent,
            UpdateDateModal,
        },
        data() {
            return {
                info: {
                    details: {
                        product: '',
                    },
                    brand: '',
                    status: 0,
                    statusDetail: {
                        childCount: 0,
                    },
                    children: [
                        {
                            CPM: '--',
                            CPC: '--',
                            CPV: '--',
                            more: {
                                package: false,
                                status: 0,
                            },

                            details: {
                                sum_of_impressions: 0,
                                sum_of_clicks: 0,
                                sum_of_views: 0,
                            },
                        },
                    ],
                },
                billing: {
                    campaignTotalAmount: 0,
                    depositAmountHT: 0,
                    totalLeftAmount: 0,
                },
                isCollapsed: false,
                isInnerCollapsed: false,

                items: [],

                fields: [
                    {
                        key: 'media',
                        label: 'MEDIAS',
                        sortable: true,
                        sortDirection: 'desc',
                    },
                    {
                        key: 'budget',
                        label: 'BUDGET',
                        sortable: true,
                        class: 'text-center',
                    },
                    {
                        key: 'dailyLogs',
                        label: 'METRICS / JOUR',
                        sortable: true,
                        class: 'text-center',
                    },

                    {
                        key: 'impressions',
                        label: 'Impressions / videos VUES',
                        thStyle: { textAlign: 'center' },
                    },
                    {
                        key: 'clics',
                        label: 'Clics / Conversions',
                        thStyle: { textAlign: 'center' },
                    },
                    { key: 'more', label: '' },
                ],
                rows: 0,

                totalRows: 1,
                currentPage: 1,

                filter: null,
                sortBy: 'media',
                sortDesc: false,
                sortDirection: 'asc',
                perPage: 10,
                activeTab: 0,
            };
        },

        async beforeMount() {
            await this.getCampaign();

            const tabs = ['global'];
            this.items.forEach((element) => {
                tabs.push(element.more.media.toLowerCase());
            });
            const hash = window.location.hash.substring(1);
            const index = tabs.findIndex((tab) => tab === hash);

            if (index !== -1) {
                this.activeTab = index - 1;
                // Remove media name hash from url
                history.replaceState({}, document.title, window.location.pathname);
            }

            let billCode = this.info.details?.paidCampaignData?.billCode;

            if (billCode) {
                await this.getBilling(billCode);
            }
        },

        computed: {
            ...mapState(['data']),

            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },

        mounted() {
            this.totalRows = this.items.length;
        },

        methods: {
            ...mapActions('app', ['getCampaignDetails']),

            dataToCopy() {
                let dataToCopy = document.querySelector('#code-to-copy');
                dataToCopy.setAttribute('type', 'text');
                dataToCopy.select();

                try {
                    document.execCommand('copy');
                    this.$toast(this.successToast('Copié'));
                } catch (err) {
                    this.$toast(this.errorToast('Une erreur est survenue'));
                }

                dataToCopy.setAttribute('type', 'hidden');
                window.getSelection().removeAllRanges();
            },
            toggleCollapse() {
                this.isCollapsed = !this.isCollapsed;
            },
            toggleInnerCollapse() {
                this.isInnerCollapsed = !this.isInnerCollapsed;
            },
            async getBilling(code) {
                this.$root.toggleGlobalLoading(true);

                const billing = await axios.get(process.env.VUE_APP_APIURL + `/metrics/api/billings/${code}`);
                this.$root.toggleGlobalLoading(false);

                this.billing.campaignTotalAmount = billing.data[0]?.details?.campaignData?.campaignTotalAmount / 100 || 0;

                this.billing.depositAmountHT = this.billing.campaignTotalAmount / 2 || 0;

                this.billing.totalLeftAmount = this.billing.campaignTotalAmount - this.billing.depositAmountHT ? this.billing.campaignTotalAmount - this.billing.depositAmountHT : '0';
            },

            getIcon(name) {
                switch (name) {
                    case 'facebook':
                        return FacebookIcon;
                    case 'facebook formulaire':
                        return FacebookIcon;
                    case 'waze - carte':
                        return WazeIcon;
                    case 'waze - plein écran':
                        return WazeIcon;
                    case 'youtube':
                        return YoutubeIcon;
                    case 'ordinateur':
                        return DesktopIcon;
                    case 'adwords':
                        return GoogleIcon;
                    case 'adwords fil rouge':
                        return GoogleIcon;
                    case 'linkedin':
                        return LinkedinIcon;
                    case 'mobile':
                        return MobileIcon;
                    case 'audio':
                        return SoundIcon;
                    case 'vidéo':
                        return VideoIcon;
                    case 'francetv':
                        return TVIcon;
                    case 'tf1':
                        return TVIcon;
                    default:
                        return DesktopIcon;
                }
            },

            calculateDailyMetrics(element, campaignMediaDuration, nb_metrics) {
                const logDatesCount = nb_metrics || 0;
                const daysLeft = campaignMediaDuration - logDatesCount > 0 ? campaignMediaDuration - logDatesCount : 0;
                const dailyMetrics = {};

                if (element.childrenDailyMetrics) {
                    element.childrenDailyMetrics.forEach((el) => {
                        if (typeof el.log_date === 'string') {
                            const date = new Date(el.log_date).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' });
                            dailyMetrics[date] = dailyMetrics[date] || {};
                            if (element.goal !== 'impressions' && typeof el.metrics?.clicks === 'number') {
                                dailyMetrics[date].mediaClicks = el.metrics.clicks;
                            }
                            if (element.goal === 'impressions' && typeof el.metrics?.impressions === 'number') {
                                dailyMetrics[date].mediaImpressions = el.metrics.impressions;
                            }
                        }
                    });
                }

                return { dailyMetrics, logDatesCount, daysLeft };
            },

            calculateFinancialMetrics(element, videoBudget, videosViewed) {
                const { sumDailyMetrics, media_support_code, childrenDailyMetrics } = element;
                let CPV = '--';

                if (media_support_code === 'YOU' && childrenDailyMetrics) {
                    childrenDailyMetrics.forEach(({ metrics }) => {
                        videoBudget += metrics.budget;
                        videosViewed += metrics.video_quartile_p100;
                    });
                    CPV = (videoBudget / videosViewed).toFixed(3);
                }

                let CPM = '--';
                let CPC = '--';

                if (
                    typeof sumDailyMetrics.sumDailyCosts === 'number' &&
                    typeof sumDailyMetrics.sumDailyImpressions === 'number' &&
                    sumDailyMetrics.sumDailyImpressions !== 0 &&
                    sumDailyMetrics.sumDailyImpressions
                ) {
                    CPM = ((sumDailyMetrics.sumDailyCosts / sumDailyMetrics.sumDailyImpressions) * 1000).toFixed(2);
                }
                if (typeof sumDailyMetrics.sumDailyCosts === 'number' && typeof sumDailyMetrics.sumDailyClicks === 'number' && sumDailyMetrics.sumDailyClicks !== 0 && sumDailyMetrics.sumDailyClicks) {
                    CPC = (sumDailyMetrics.sumDailyCosts / sumDailyMetrics.sumDailyClicks).toFixed(2);
                }
                return { CPV, CPM, CPC };
            },

            calculateComparisonMetrics(element, dailyMetrics) {
                const { sumDailyMetrics, details } = element;
                const { logDatesCount, daysLeft } = dailyMetrics;

                const quantityRatio = details.quantity / (logDatesCount + daysLeft);
                const compareImpressions = sumDailyMetrics.sumDailyImpressions - quantityRatio * logDatesCount;
                const compareClicks = sumDailyMetrics.sumDailyClicks - quantityRatio * logDatesCount;
                const compareViews = details.sum_of_views - quantityRatio * logDatesCount;

                return { compareImpressions, compareClicks, compareViews };
            },

            createTempItem({ element, campaignDetails, dailyMetrics, financialMetrics, comparisonMetrics }) {
                const { campaignMediaDuration, logDatesCount, daysLeft, fullName } = campaignDetails;

                const tempItem = {
                    media: {
                        name: fullName,
                        icon: this.getIcon(fullName.toLowerCase()),
                    },
                    budget: element.metrics?.childBudget,
                    goal: element.goal || '',
                    compareImpressions: comparisonMetrics.compareImpressions,
                    compareClicks: comparisonMetrics.compareClicks,
                    compareViews: comparisonMetrics.compareViews,

                    budgetCampaign: element.sumDailyMetrics?.sumDailyCosts,
                    availableFunds: 0,
                    budgetPercentage: 0,

                    dailyLogs: {
                        daysCampaignRealized: logDatesCount,
                        daysCampaign: campaignMediaDuration,
                        daysLeft,
                    },

                    cpa: { cpaDaily: '--', pourcentage: 0 },
                    cpm: { cpmDaily: financialMetrics.CPM, pourcentage: 0 },
                    cpc: { cpcDaily: financialMetrics.CPC, pourcentage: 0 },
                    cpv: { cpvDaily: financialMetrics.CPV, pourcentage: 0 },

                    date: {
                        start_date: element.start_date,
                        end_date: element.end_date,
                        pourcentage: 0,
                    },

                    more: {
                        status: element.status,
                        campaign_code: element.campaign_code,
                        media: element.media_support_code,
                        providerId: element.details.provider_data?.id,
                        brand: element.brand,
                        provider: element.details.provider,
                        provider_data: element.details.provider_data,
                        salePoint: this.info.details.point_text,
                        salePointCode: this.info.details.point_code,
                        package: element.details.package,
                        mediaName: fullName,
                        otherChildrenCampaign: this.info.children.filter((child) => child.media_support_code !== element.media_support_code),
                        price: element.details.unit_price_excluding_taxes,
                    },

                    details: {
                        sum_of_impressions: element.details?.sum_of_impressions || 0,
                        sum_of_clicks: element.details?.sum_of_clicks || 0,
                        sum_of_views: element.details?.sum_of_views || 0,
                    },

                    totalMetrics: {
                        clics: element?.sumDailyMetrics?.sumDailyClicks || 0,
                        impressions: element?.sumDailyMetrics.sumDailyImpressions || 0,
                    },

                    quantity: { total: element.details.quantity, pourcentage: 0 },

                    availableMetrics: {
                        clics: 0,
                        impressions: 0,
                        views: 0,
                    },

                    impressions: {},
                    clicks: {},

                    logsMetrics: dailyMetrics.dailyMetrics,
                    budgetDifference: null,
                };

                tempItem.budgetPercentage = Math.round((parseInt(tempItem.budgetCampaign) / (tempItem.budget / 100)) * 100);

                if (tempItem.more.media === 'YOU') {
                    tempItem.budgetPercentage = Math.round((parseInt(tempItem.budgetCampaign) / tempItem.more.price) * 100);
                }

                // Calcul du % de difference entre budget reelement depense et le bdget qui devait etre depense pour indiqer la couleur dans progress bar
                let budgetPerDay = tempItem.budget / 100 / tempItem.dailyLogs.daysCampaign;
                let budgetLogs = budgetPerDay * tempItem.dailyLogs.daysCampaignRealized;

                tempItem.budgetDifference = tempItem.budgetCampaign - budgetLogs;

                if (tempItem.dailyLogs.daysCampaign !== 0) {
                    tempItem.date.pourcentage = Math.floor((tempItem.dailyLogs.daysCampaignRealized / tempItem.dailyLogs.daysCampaign) * 100);
                }

                if (
                    tempItem.dailyLogs.daysCampaignRealized !== 0 &&
                    typeof tempItem.quantity.total === 'number' &&
                    typeof tempItem.totalMetrics.clics === 'number' &&
                    tempItem.dailyLogs.daysLeft !== 0
                ) {
                    tempItem.availableMetrics.clics = Math.floor((tempItem.quantity.total - tempItem.totalMetrics.clics) / tempItem.dailyLogs.daysLeft).toFixed(0);
                }

                if (
                    tempItem.dailyLogs.daysCampaignRealized !== 0 &&
                    typeof tempItem.quantity.total === 'number' &&
                    typeof tempItem.totalMetrics.impressions === 'number' &&
                    tempItem.dailyLogs.daysLeft !== 0
                ) {
                    tempItem.availableMetrics.impressions = Math.floor((tempItem.quantity.total - tempItem.totalMetrics.impressions) / tempItem.dailyLogs.daysLeft).toFixed(0);
                }

                if (
                    tempItem.dailyLogs.daysCampaignRealized !== 0 &&
                    typeof tempItem.quantity.total === 'number' &&
                    typeof tempItem.details.sum_of_views === 'number' &&
                    tempItem.dailyLogs.daysLeft !== 0
                ) {
                    tempItem.availableMetrics.views = Math.floor((tempItem.quantity.total - tempItem.details.sum_of_views) / tempItem.dailyLogs.daysLeft).toFixed(0);
                }

                const quantityRatio = element.details.quantity / tempItem.dailyLogs.daysCampaign;
                const daysCampaignRealized = tempItem.dailyLogs.daysCampaignRealized;

                tempItem.compareImpressions = tempItem.totalMetrics.impressions - quantityRatio * daysCampaignRealized;
                tempItem.compareClicks = tempItem.totalMetrics.clics - quantityRatio * daysCampaignRealized;

                if (tempItem.dailyLogs.daysCampaign !== 0 && tempItem.budget && tempItem.budget >= tempItem.budgetCampaign && tempItem.dailyLogs.daysLeft > 0) {
                    tempItem.availableFunds = ((tempItem.budget / 100 - tempItem.budgetCampaign) / tempItem.dailyLogs.daysLeft).toFixed(2);
                }

                if (tempItem.dailyLogs.daysCampaign !== 0 && tempItem.more.media === 'YOU') {
                    tempItem.availableFunds = ((tempItem.more.price - tempItem.budgetCampaign) / tempItem.dailyLogs.daysLeft).toFixed(2);
                }

                if (element.metrics) {
                    if (element.goal === 'impressions') {
                        tempItem.impressions = {
                            value: element.sumDailyMetrics.sumDailyImpressions || 0,
                            objectif: element.details.quantity,
                            objectivePercentage:
                                element.sumDailyMetrics.sumDailyImpressions > 0 && element.details.quantity > 0
                                    ? Math.floor((element.sumDailyMetrics.sumDailyImpressions / element.details.quantity) * 100)
                                    : 0,
                        };

                        tempItem.clics = {
                            value: element.sumDailyMetrics.sumDailyClicks || 0,
                        };

                        // CPM commandé
                        if (tempItem.quantity.total > 0) {
                            tempItem.cpm.cpmCommanded = (parseInt(element.metrics.childBudget / 100) / tempItem.quantity.total) * 1000;
                            if (tempItem.cpm.cpmCommanded > 0) {
                                tempItem.cpm.pourcentage = parseInt((((tempItem.cpm.cpmDaily - tempItem.cpm.cpmCommanded) / tempItem.cpm.cpmCommanded) * 100).toFixed(2));
                            }
                        }

                        tempItem.quantity.pourcentage = ((tempItem.totalMetrics.impressions / tempItem.quantity.total) * 100).toFixed(2);
                    } else if (element.goal === 'clicks') {
                        tempItem.clics = {
                            value: element.sumDailyMetrics.sumDailyClicks || 0,
                            objectif: element.details.quantity,
                            objectivePercentage:
                                element.sumDailyMetrics.sumDailyClicks > 0 && element.details.quantity > 0 ? Math.floor((element.sumDailyMetrics.sumDailyClicks / element.details.quantity) * 100) : 0,
                        };

                        tempItem.impressions = {
                            value: element.sumDailyMetrics.sumDailyImpressions || 0,
                        };

                        // CPC commandé
                        if (tempItem.quantity.total > 0) {
                            tempItem.cpc.cpcCommanded = (parseInt(element.metrics.childBudget / 100) / tempItem.quantity.total).toFixed(2);
                            if (tempItem.cpc.cpcCommanded > 0) {
                                tempItem.cpc.pourcentage = parseInt(((tempItem.cpc.cpcDaily - tempItem.cpc.cpcCommanded) / tempItem.cpc.cpcCommanded).toFixed(2));
                            }
                            tempItem.quantity.pourcentage = ((tempItem.totalMetrics.clics / tempItem.quantity.total) * 100).toFixed(2);
                        }
                    } else if (element.goal === 'video_quartile_p100') {
                        tempItem.views = {
                            value: element.details.sum_of_views || 0,
                            objectif: element.details.quantity,
                            objectivePercentage: element.details.sum_of_views > 0 && element.details.quantity > 0 ? Math.floor((element.details.sum_of_views / element.details.quantity) * 100) : 0,
                        };

                        // CPV commandé
                        tempItem.cpv.cpvCommanded = (parseInt(element.metrics.childBudget / 100) / tempItem.quantity.total).toFixed(3);
                        tempItem.cpv.pourcentage = Number(((tempItem.cpv.cpvDaily - tempItem.cpv.cpvCommanded) / tempItem.cpv.cpvCommanded) * 100);

                        if (tempItem.details.sum_of_views) {
                            tempItem.quantity.pourcentage = (tempItem.details.sum_of_views / tempItem.quantity.total).toFixed(2);
                        }

                        tempItem.impressions = {
                            value: element.sumDailyMetrics.sumDailyImpressions || 0,
                        };
                        tempItem.clics = {
                            value: element.sumDailyMetrics.sumDailyClicks || 0,
                        };
                    }

                    tempItem.details.sum_of_impressions = element.details?.sum_of_impressions;
                    tempItem.details.sum_of_clicks = element.details?.sum_of_clicks;
                    tempItem.details.sum_of_views = element.details?.sum_of_views;
                }

                return tempItem;
            },

            calculateAvailableMetrics(total, current, daysLeft) {
                if (daysLeft === 0 || total === 0) return 0;
                return Math.floor((total - current) / daysLeft).toFixed(0);
            },

            async getCampaign() {
                this.items = [];
                const mediaMetrics = this.$store.state.app.mediaMetrics;
                this.$root.toggleGlobalLoading(true);

                let videoBudget = 0;
                let videosViewed = 0;

                this.info = await this.getCampaignDetails();

                if (!this.info.children) {
                    this.$root.toggleGlobalLoading(false);
                    return;
                }

                let inProgressChildren = 0;

                this.info.statusDetail = {
                    childCount: this.info.children.length,
                    inProgressChildren: 0,
                };

                this.info.children.forEach((element) => {
                    const { start_date, end_date, media_support_code, status, nb_metrics } = element;

                    const startDate = new Date(start_date);
                    const endDate = new Date(end_date);
                    const campaignMediaDuration = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

                    const matchedMediaMetric = mediaMetrics.find((metric) => metric.media === media_support_code);
                    const fullName = matchedMediaMetric ? matchedMediaMetric.label : '';

                    if (matchedMediaMetric && matchedMediaMetric.geolinkDetails) {
                        element.goal = JSON.parse(matchedMediaMetric.geolinkDetails).goal;
                    }

                    if (status === 1) {
                        this.info.statusDetail.inProgressChildren = ++inProgressChildren;
                    }

                    const dailyMetrics = this.calculateDailyMetrics(element, campaignMediaDuration, nb_metrics);
                    const financialMetrics = this.calculateFinancialMetrics(element, videoBudget, videosViewed);
                    const comparisonMetrics = this.calculateComparisonMetrics(element, dailyMetrics);

                    const tempItem = this.createTempItem({
                        element,
                        campaignDetails: {
                            campaignMediaDuration,
                            logDatesCount: dailyMetrics.logDatesCount,
                            daysLeft: dailyMetrics.daysLeft,
                            fullName,
                        },
                        dailyMetrics,
                        financialMetrics,
                        comparisonMetrics,
                    });

                    if (matchedMediaMetric) {
                        this.items.push(tempItem);
                    } else {
                        console.log('Ce media ne match avec aucun mediaMetric');
                    }
                });

                this.$root.toggleGlobalLoading(false);
            },

            getProgressBarClass(compareValue, difference) {
                if (compareValue > 0) {
                    return 'bg-success';
                }

                switch (true) {
                    case difference > 15:
                        return 'bg-danger';
                    case difference < 5:
                        return 'bg-success';
                    default:
                        return 'bg-warning';
                }
            },
            getProgressBarClassImpressions(compareImpressions, budgetRealise, objectif, daysCampaign, days) {
                const budgetFinal = (objectif / daysCampaign) * days;
                const difference = (budgetRealise / budgetFinal) * 100;

                return this.getProgressBarClass(compareImpressions, difference);
            },

            getProgressBarClassClics(compareClicks, budgetRealise, objectif, daysCampaign, days) {
                const budgetFinal = (objectif / daysCampaign) * days;

                const difference = (budgetRealise / budgetFinal) * 100;

                return this.getProgressBarClass(compareClicks, difference);
            },

            getVerticalBarValue(objectif, totalDays, days) {
                const daily = (objectif / totalDays) * days;
                const response = (daily / objectif) * 100;

                return Math.floor(response);
            },
            downloadReport(data) {
                const campaignCode = data.campaign_code;

                const pointText = data.details.point_text[0].replaceAll(' ', '-');
                const startDate = data.start_date.replaceAll('/', '-');
                const brand = campaignCode.split('_')[1].toLowerCase();

                this.$root.toggleGlobalLoading(true);
                http.getPDF(this.$store.state.app.apiCampaignUrl + '/api/v1/' + brand + '/campaign/' + campaignCode + '/metrics', localStorage.getItem('accessToken'))
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        if (res && res[0].data.byteLength > 0) {
                            const blob = new Blob([res[0].data], {
                                type: res[0].headers['content-type'],
                            });

                            FileSaver.saveAs(blob, pointText + '_' + startDate + '_bilan.pdf');
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Le bilan a été téléchargé',
                                    icon: 'smile',
                                    variant: 'success',
                                },
                            });
                        } else {
                            this.$toast({
                                component: 'toast',
                                props: {
                                    title: 'Pas de bilan à télécharger',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la génération du rapport',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
            tradingRoute(code) {
                this.$router.push({
                    name: 'trading',
                    params: {
                        campaign_code: code,
                    },
                });
            },
            budgetClassColor(difference, budget, isPackage) {
                const formattedBudget = Number(budget) / 100;
                const differenceBudget = Math.round((difference / formattedBudget) * 100);

                if (!isPackage) {
                    // Si campagne n'est pas un forfait
                    const SMALL_DIFFERENCE_THRESHOLD = 5;
                    const MEDIUM_DIFFERENCE_THRESHOLD = 20;

                    if (Math.abs(differenceBudget) <= SMALL_DIFFERENCE_THRESHOLD) {
                        return 'text-success';
                    } else if (Math.abs(differenceBudget) <= MEDIUM_DIFFERENCE_THRESHOLD) {
                        return 'text-warning';
                    } else {
                        return 'text-danger';
                    }
                } else {
                    // Si c'est un forfait
                    const SMALL_POSITIVE_DIFFERENCE_THRESHOLD = 0;
                    const MEDIUM_NEGATIVE_DIFFERENCE_THRESHOLD = -20;

                    if (differenceBudget >= SMALL_POSITIVE_DIFFERENCE_THRESHOLD) {
                        return 'text-success';
                    } else if (differenceBudget > MEDIUM_NEGATIVE_DIFFERENCE_THRESHOLD) {
                        return 'text-warning';
                    } else {
                        return 'text-danger';
                    }
                }
            },

            startDateCampaign(startDate, endDateRaw, code) {
                this.$refs['update-date-modal'].open(startDate, endDateRaw, code);
            },

            updateDateCampaign(campaignsDatesToUpdate) {
                this.$root.toggleGlobalLoading(true);
                var data = {
                    campaigns: campaignsDatesToUpdate,
                };

                this.$store
                    .dispatch('app/updateCampaignDate', data)
                    .then((res) => {
                        this.$emit('update');

                        this.$refs['update-date-modal'].close();
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Date(s) modifiée avec succès',
                                icon: 'CheckCircleIcon',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Erreur lors de la modification des dates',
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    })
                    .finally(() => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '~@core/scss/base/bootstrap-extended/include';
    @import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

    .gap {
        gap: 0.5rem;
    }

    .date-badge {
        background: rgba(115, 103, 240, 0.16);
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;
        height: 24px;
        margin: auto;
        width: fit-content;
    }

    .details {
        :deep(td) {
            padding-right: 0 !important;
            text-align: right;
        }
    }

    .amount {
        font-size: 20px;
    }

    .amount-prcnt {
        font-size: 15px;
    }

    .cost {
        font-size: 20px;
    }
</style>
