import { render, staticRenderFns } from "./DetailsCampaign.vue?vue&type=template&id=841fe3c0&scoped=true"
import script from "./DetailsCampaign.vue?vue&type=script&lang=js"
export * from "./DetailsCampaign.vue?vue&type=script&lang=js"
import style0 from "./DetailsCampaign.vue?vue&type=style&index=0&id=841fe3c0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "841fe3c0",
  null
  
)

export default component.exports